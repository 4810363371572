<template>
  <section style="background-color: #fff">
    <div class="">
      <el-form
        @submit.native.prevent
        :model="dataform"
        ref="dataform"
        :rules="rules"
        label-width="100px"
        class="addForm"
      >
        <el-row :gutter="24">
          <el-col :span="23">
            <el-form-item label="邮件类型：" prop="emailType">
              <el-select
                v-model="dataform.emailType"
                placeholder="请选择"
                clearable
                filterable
                style="width: 50%"
              >
                <el-option
                  v-for="(item, index) in type"
                  :key="'a_' + index"
                  :value="item.value"
                  :label="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="23">
            <el-form-item label="模板名称：" prop="templateName">
              <el-input
                v-model="dataform.templateName"
                placeholder="最多不超过50个字"
                maxlength="50"
                style="width: 50%"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="23">
            <el-form-item label="主题：" prop="templateSubject">
              <el-input
                v-model="dataform.templateSubject"
                placeholder="最多不超过100个字"
                maxlength="100"
                ref="targetIn"
                style="width: 50%"
              >
                <template #append>
                  <span class="isCheack" @click="addVariables">添加变量</span>
                </template>
              </el-input>
              <p
                v-for="(item, index) in annexListdata"
                :key="'ind_' + index"
                class="flexRow"
              >
                <span> {{ item.fileName }}</span>
                <i class="el-icon-close" @click="checkAnnex(index)"></i>
              </p>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
           <el-col :span="23">
          <tinymce
            v-model="dataform.content"
            :height="300"
            @annexList="annexList"
            @showTheDig="getValue"
            :ishowAnnexes="true"
            :ishowVariables="true"
            ref="removeAnList"
            style="margin-left:100px;"
          />
           </el-col>
        </el-row>
      </el-form>
    </div>
    <!--  创建文件-->
    <el-dialog
      :visible.sync="dialogVisible"
      :modal-append-to-body="false"
      append-to-body
      title="邮件变量"
      :close-on-click-modal="false"
      width="600px"
      destroy-on-close
    >
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="我的" name="我的"></el-tab-pane>
        <el-tab-pane label="人才" name="人才"></el-tab-pane>
        <el-tab-pane label="客户联系人" name="客户联系人"></el-tab-pane>
        <el-tab-pane label="职位" name="职位"></el-tab-pane>
        <el-tab-pane
          v-if="dataform.emailType == '给候选人：客户面试通知'"
          label="面试"
          name="面试"
        ></el-tab-pane>
      </el-tabs>
      <div>
        <span
          class="tip"
          v-for="(item, index) in list"
          :key="index"
          @click="getTag(item)"
        >
          {{ item.name }}
        </span>
      </div>
    </el-dialog>
  </section>
</template>
<script>
import Tinymce from "@/components/Tinymce";
import {
  addEmailTemplate,
  userEmailControllerDetail, //查看邮件模板详情
  editEmailTemplate, //修改邮件模板
} from "../../api/api";
export default {
  components: {
    Tinymce,
  },
  data() {
    return {
      id: "",
      dataform: {
        emailType: "",
        templateName: "",
        templateSubject: "",
        content: "",
      },
      rules: {
        emailType: [
          { required: true, message: "请选择邮件类型", trigger: "change" },
        ],
        templateName: [
          { required: true, message: "请填写模板名称", trigger: "blur" },
        ],
        templateSubject: [
          { required: true, message: "请填写主题", trigger: "blur" },
        ],
      },
      type: [
        {
          value: "给客户：简历推荐",
          name: "给客户：简历推荐",
        },
        {
          value: "给客户：背景调查",
          name: "给客户：背景调查",
        },
        {
          value: "给候选人：客户面试通知",
          name: "给候选人：客户面试通知",
        },
        {
          value: "其他",
          name: "其他",
        },
      ],
      annexListdata: [], //附件
      dialogVisible: false,
      activeName: "我的",
      VariablesList: {
        my: [
          {
            value: "{{myName}}",
            name: "姓名",
          },
          {
            value: "{{myEmail}}",
            name: "邮箱",
          },
        ],
        resume: [
          {
            value: "{{resumeName}}",
            name: "姓名",
          },
          {
            value: "{{resumeEmail}}",
            name: "邮箱",
          },
          {
            value: "{{resumeMobile}}",
            name: "手机号",
          },
          {
            value: "{{resumeCompany}}",
            name: "目前公司",
          },
          {
            value: "{{resumePosition}}",
            name: "目前职位",
          },
        ],
        contact: [
          {
            value: "{{contactName}}",
            name: "姓名",
          },
          {
            value: "{{contactEmail}}",
            name: "邮箱",
          },
          {
            value: "{{contactMobile}}",
            name: "联系电话",
          },
          {
            value: "{{contactCompany}}",
            name: "公司",
          },
          {
            value: "{{contactPosition}}",
            name: "职位",
          },
          {
            value: "{{contactAddress}}",
            name: "地址",
          },
        ],
        position: [
          {
            value: "{{positionCompany}}",
            name: "公司",
          },
          {
            value: "{{positionName}}",
            name: "职位",
          },
          {
            value: "{{positionStatus}}",
            name: "状态",
          },
          {
            value: "{{positionStartDate}}",
            name: "开始时间",
          },
          {
            value: "{{positionEndDate}}",
            name: "结束时间",
          },
        ],
        interview: [
          {
            value: "{{interviewTime}}",
            name: "面试时间",
          },
          {
            value: "{{interviewRound}}",
            name: "面试轮数",
          },
        ],
      },
      list: [],
      isRich: null,
    };
  },
  methods: {
    getDetail(id) {
      this.id = id;
      userEmailControllerDetail({ id: id }).then((res) => {
        if (res.success) {
          this.dataform = res.result;
          this.annexListdata = res.result.attachmentPaths;
          this.$refs.removeAnList.setData(res.result.attachmentPaths)
        }
      });
    },
    annexList(arr) {
      this.annexListdata = arr;
    },
    checkAnnex(i) {
      //点击删除
      this.$confirm("是否删除该附件？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.annexListdata.splice(i, 1);
          this.$refs.removeAnList.handleAnRemove(this.annexListdata);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    addVariables() {
      this.activeName = "我的";
      this.list = this.VariablesList.my;
      this.dialogVisible = true;
    },
    getValue(val) {
      this.isRich = val;
      this.addVariables();
    },
    handleClick(val) {
      if (val.name == "我的") {
        this.list = this.VariablesList.my;
      } else if (val.name == "人才") {
        this.list = this.VariablesList.resume;
      } else if (val.name == "客户联系人") {
        this.list = this.VariablesList.contact;
      } else if (val.name == "职位") {
        this.list = this.VariablesList.position;
      } else if (val.name == "面试") {
        this.list = this.VariablesList.interview;
      }
    },
    getTag(row) {
      if (this.isRich == "isrichText") {
        let val = this.dataform.content;
        this.$refs.removeAnList.addNewText(row.value);
        // this.dataform.content = val + '<p>'+row.value+'</p>';
        this.dialogVisible = false;
        this.isRich = null;
      } else {
        // let val = this.dataform.templateSubject;
        // this.dataform.templateSubject = val + row.value;
        this.insertText(row.value);
        this.dialogVisible = false;
      }
    },
    insertText(insertTxt) {
      // 获取el-input中的input元素
      let elInput = this.$refs.targetIn.$el.firstElementChild;
      // 获取el-input的值
      let txt = elInput.value;
      // 做插入前做长度校验(如果有这个需要的话)
      if (txt) {
        if (txt.length + insertTxt.length > 100) {
          return;
        }
      }
      // 获取选区开始位置
      let startPos = elInput.selectionStart;
      // 获取选区结束位置
      let endPos = elInput.selectionEnd;
      if (startPos === undefined || endPos === undefined) return;

      // 将文本插入光标位置
      this.dataform.templateSubject =
        txt.substring(0, startPos) + insertTxt + txt.substring(endPos);
      // 将光标移至文本末尾
      elInput.focus();
      elInput.selectionStart = startPos + insertTxt.length;
      elInput.selectionEnd = startPos + insertTxt.length;
    },
    save(formName) {
      return new Promise((resolve, reject) => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (this.dataform.content == "") {
              this.$message.error("内容不能为空");
              return;
            }
            let req = null;
            this.dataform.id = this.id;
            this.dataform.attachmentPaths = this.annexListdata;
            if (this.dataform.userId) {
              req = editEmailTemplate(this.dataform);
            } else {
              req = addEmailTemplate(this.dataform);
            }
            req.then((res) => {
              if (res.success) {
                this.$message.success("保存成功");
                this.$emit("loadList");
                resolve();
              }
            });
          }
        });
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.tip {
  margin-right: 10px;
  padding: 5px 10px;
  border: 1px #ececec solid;
  border-radius: 4px;
  cursor: pointer;
}
.flexRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
}
.isCheack:hover{
  color: #e60012;
  cursor: pointer;
}
</style>