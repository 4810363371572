<template>
  <section class="box-height" style="margin-bottom: 70px">
    <div class="box-title emailSet" style="border: 1px #e5e5e5 solid">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane name="基本设置">
          <span class="tagW" slot="label">基本设置</span>
        </el-tab-pane>
        <el-tab-pane name="邮件模板设置">
          <span class="tagW" slot="label">邮件模板设置</span>
        </el-tab-pane>
      </el-tabs>
      <div
        v-if="activeName == '基本设置'"
        style="margin-top: 5px; padding-bottom: 40px"
      >
        <el-form
          @submit.native.prevent
          :model="from"
          ref="from"
          :rules="rules"
          label-width="130px"
          class="addForm"
        >
          <el-form-item
            label="授权码："
            prop="authCode"
            style="margin-bottom: 6px"
          >
            <div>
              <el-input
                show-password
                v-model.trim="from.authCode"
                style="width: 30%; margin-right: 10px"
              ></el-input>
              <span class="tabHref" @click="goEmailGetCode"
                >如何获取授权码?</span
              >
            </div>
            <p
              style="
                width: 90%;
                display: flex;
                justify-content: space-between;
                line-height: 30px;
              "
            >
              <span style="color: #999">请绑定{{ email }}的授权码</span>
            </p>
          </el-form-item>
          <el-form-item label="签名：" prop="versionCode">
            <el-checkbox v-model="from.useSignature">启用</el-checkbox>
            <tinymce
              v-model="from.signature"
              :height="300"
              @annexList="annexList"
              :ishowImage="true"
              ref="removeAnList"
              style="width: 90%"
            />
          </el-form-item>
          <el-form-item label="" prop="versionCode">
            <el-button
              size="small"
              type="primary"
              @click="save('from')"
              :loading="saveloading"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div v-if="activeName == '邮件模板设置'" style="padding-bottom: 20px">
        <el-row>
          <el-col>
            <div style="margin-left: 15px">
              <el-button
                size="mini"
                style="margin-right: 15px"
                @click="addNewTable()"
                >新增模板</el-button
              >
              <el-dropdown
                trigger="click"
                v-show="multipleSelection.length > 0"
              >
                <el-button type="default" plain size="mini">
                  批量操作<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="1" @click.native="createFileBtn()"
                    >共享</el-dropdown-item
                  >
                  <el-dropdown-item :command="2" @click.native="delData()"
                    >删除</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <el-pagination
              :hide-on-single-page="page.total > 10 ? false : true"
              v-if="page.total > 0"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="page.current"
              :page-sizes="[10, 20, 50, 100]"
              :pager-count="5"
              :page-size="page.size"
              layout="total,prev, pager, next,sizes"
              :total="page.total"
              style="padding-right: 15px"
            ></el-pagination>
          </el-col>
          <el-col>
            <el-table
              :header-cell-style="{
                'background-color': '#f5f7fa',
              }"
              :data="InfoData"
              class="tabBorder custor is-top"
              :lading="tableLoading"
              @selection-change="handleSelectionChange"
            >
              <el-table-column
                type="selection"
                width="40"
                align="center"
                fixed
              ></el-table-column>
              <el-table-column label="类型" width="230" prop="emailType">
              </el-table-column>
              <el-table-column label="模板名称" width="270" prop="templateName">
              </el-table-column>
              <el-table-column label="主题" prop="templateSubject">
              </el-table-column>
              <el-table-column
                label="创建人"
                width="150"
                prop="templateCreateUser"
              >
              </el-table-column>
              <el-table-column
                label="创建时间"
                width="150"
                prop="templateCreateDate"
              >
              </el-table-column>
              <el-table-column label="操作" width="150" prop="">
                <template slot-scope="scope">
                  <span class="tabHref" @click="addNewTable(scope.row)"
                    >编辑</span
                  >
                  <el-divider direction="vertical"></el-divider>
                  <span class="tabHref" @click="createFileBtn(scope.row)"
                    >共享</span
                  >
                  <el-divider direction="vertical"></el-divider>
                  <span class="tabHref" @click="delData(scope.row.id)"
                    >删除</span
                  >
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              :hide-on-single-page="page.total > 10 ? false : true"
              v-if="page.total > 0"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="page.current"
              :page-sizes="[10, 20, 50, 100]"
              :pager-count="5"
              :page-size="page.size"
              layout="total,prev, pager, next,sizes"
              :total="page.total"
              style="padding-right: 15px"
            ></el-pagination>
          </el-col>
        </el-row>
      </div>
    </div>
    <!--  共享邮件模板-->
    <el-dialog
      :visible.sync="createFile"
      :modal-append-to-body="false"
      append-to-body
      title="共享邮件模板"
      :close-on-click-modal="false"
      width="600px"
      destroy-on-close
      :before-close="handleClose1"
    >
      <el-form
        v-if="createFile"
        :model="fileList"
        ref="fileList"
        label-width="120px"
      >
        <el-col>
          <el-form-item label="邮件模板名称：" prop="name">
            <span v-for="(item, index) in nameList" :key="index"
              ><span v-if="index > 0">、</span>{{ item }}
            </span>
          </el-form-item>
        </el-col>

        <el-col :span="24" style="margin-bottom: 15px">
          <div
            class="fl"
            style="width: 115px; line-height: 32px; text-align: right"
          >
            共享给：
          </div>
          <div class="fr" style="width: calc(100% - 120px); position: relative">
            <template>
              <div
                class="shareRow"
                v-for="(i, inx) in fileList.shares"
                :key="i.id"
              >
                <el-select
                  style="width: 20%"
                  filterable
                  collapse-tags
                  v-model="i.type"
                  @change="diffType"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in shareTypes"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>

                <el-select
                  style="width: 58%; margin-left: 8px"
                  filterable
                  collapse-tags
                  multiple
                  v-if="i.type == '同事'"
                  v-model="i.shareIds"
                  @change="workmate($event, inx)"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in diffShareData"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>

                <el-cascader
                  style="width: 58%; margin-left: 8px"
                  v-if="i.type == '团队'"
                  v-model="i.teamIds"
                  class="fromInput"
                  collapse-tags
                  @change="teammate($event, inx)"
                  :options="teamValueOptions"
                  :props="teamProps"
                ></el-cascader>

                <span class="operationEditOrDel">
                  <span
                    class="miniBtn el-icon-plus"
                    @click="addShares(i, inx)"
                  ></span>
                  <span
                    class="miniBtn el-icon-close"
                    @click="delShares(i, inx)"
                  ></span>
                </span>
              </div>
              <span
                class="operationEditOrDel"
                v-if="fileList.shares.length == 0"
                style="line-height: 32px"
              >
                <span
                  class="miniBtn el-icon-plus tabHref"
                  @click="addShares(i, inx)"
                  style="margin-left: 0"
                  >添加</span
                >
              </span>
            </template>
          </div>
        </el-col>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" plain size="mini" @click="handleClose1"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="mini"
          :disabled="isdisabled"
          :loading="saveloading"
          @click="addOrEditFavorite('fileList')"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <!-- 抽屉弹窗 -->
    <el-drawer
      :visible.sync="drawerVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleClose"
      destroy-on-close
    >
      <div slot="title" class="drawerTitle">邮件模板</div>
      <div class="drawerCon">
        <addOrEdit
          v-if="drawerVisible"
          ref="addOrEdit"
          @loaoList="getEmailTemplatePage()"
        ></addOrEdit>

        <div class="drawerCon_footer fixedFoot" style="text-align: right">
          <el-button type="default" plain size="mini" @click="handleClose()"
            >取消</el-button
          >
          <el-button
            type="primary"
            size="mini"
            :loading="saveloading"
            @click="saveInfos"
            style="margin-right: 15px"
            >保存</el-button
          >
        </div>
      </div>
    </el-drawer>
  </section>
</template>

<script>
import {
  curemail, //当前登陆人的邮箱
  setSignature, //设置当前登陆人邮件签名
  emailTemplatePage, //分页查询邮件模板
  deleteEmailTemplate, //删除
  userOptions,
  permissionTree,
  shareEmailTemplate, //共享邮件模板
} from "../../api/api";
import { PreviewUrl } from "../../api/http";
import Tinymce from "@/components/Tinymce";
import addOrEdit from "../../components/tools/addOrEditEmail";
export default {
  components: {
    Tinymce,
    addOrEdit,
  },
  data() {
    return {
      activeName: "基本设置",
      from: {
        authCode: null,
        useSignature: false,
        signature: null,
      },
      rules: {
        authCode: [
          { required: true, message: "请填写授权码", trigger: "blur" },
        ],
      },
      email: null,
      saveloading: false,
      page: {
        total: 0,
        size: 50,
        current: 1,
      },
      InfoData: [],
      tableLoading: false,
      multipleSelection: [],
      createFile: false,
      fileList: {
        id: "",
        name: "",
        shares: [
          {
            shareTypeDefault: "同事",
            shareIds: [],
            teamIds: [],
            type: "同事",
          },
        ],
        type: "",
        version: 0,
      },
      shareTypes: ["同事", "团队", "所有人"],
      diffShareData: [],
      teamValueOptions: [],
      teamProps: {
        label: "name",
        value: "id",
        multiple: true,
      },
      nameList: [],
      nameidList: [],
      drawerVisible: false,
      isdisabled: true,
    };
  },
  created() {
    this.getcuremail();
    this.userOptions(); //同事
    this.OrganizinList(); //组织架构
  },
  methods: {
    handleClick(val) {
      if (val.name == "邮件模板设置") {
        this.getEmailTemplatePage();
      } else {
        this.getcuremail();
      }
    },
    annexList(arr) {
      this.annexListdata = arr;
    },
    //获取当前登陆人的邮箱
    getcuremail() {
      curemail().then((res) => {
        if (res.success) {
          this.email = res.result.curEmail;
          if (res.result.userEmailSignature !== null) {
            this.from = res.result.userEmailSignature;
          }
        }
      });
    },
    //设置当前登陆人邮件签名
    save(formName) {
      this.saveloading = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          setSignature(this.from).then((res) => {
            if (res.success) {
              this.$message.success("保存成功！");
              this.saveloading = false;
            }
          });
        } else {
          this.saveloading = false;
        }
      });
    },
    //获取分页查询邮件模板
    getEmailTemplatePage() {
      window.scrollTo(0, 0);
      this.tableLoading = true;
      let params = {
        page: {},
      };
      params.page = this.page;
      emailTemplatePage(params).then((res) => {
        if (res.success) {
          this.InfoData = res.result.records;
          this.page.total = res.result.total;
          this.tableLoading = false;
        }
      });
    },
    //表格选择
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    createFileBtn(row) {
      if (row) {
        this.nameList[0] = row.templateName;
        this.nameidList[0] = row.templateId;
        this.createFile = true;
      } else {
        if (this.multipleSelection.length == 0) {
          this.$message.error("至少选择一个模板共享！");
        } else {
          this.multipleSelection.forEach((i) => {
            this.nameList.push(i.templateName);
            this.nameidList.push(i.templateId);
          });
          this.createFile = true;
        }
      }
    },
    delData(id) {
      let multipleSelection = [];
      this.multipleSelection.forEach((i) => {
        multipleSelection.push(i.id);
      });
      let ids = id ? [id] : multipleSelection;
      let div =
        "<p style='font-weight:bold;text-align:center'>确认是否删除</p>" +
        "<div style='color:#999;text-align:center'>如果是共享模板，共享给别人的模板不会同步删除</div>";
      if (ids.length == 0) {
        this.$message.error("至少选择一个模板删除！");
      } else {
        this.$confirm(div, "删除确认", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          customClass: "altCls",
          dangerouslyUseHTMLString: true,
        }).then(() => {
          deleteEmailTemplate({ ids: ids }).then((res) => {
            if (res.success) {
              this.$message.success("删除成功！");
              this.getEmailTemplatePage();
            }
          });
        });
      }
    },
    handleSizeChange(val) {
      //pageSize 改变时会触发
      this.page.size = val;
      this.page.current = 1;
      this.getEmailTemplatePage();
    },
    handleCurrentChange(val) {
      //currentPage 改变时会触发
      // this.filter.page.pages = val;
      this.page.current = val;
      this.getEmailTemplatePage();
    },
    addNewTable(row) {
      let _this = this;
      if (row) {
        _this.drawerVisible = true;
        setTimeout(function () {
          _this.$refs.addOrEdit.getDetail(row.id);
        }, 100);
      } else {
        _this.drawerVisible = true;
      }
    },
    // 同事
    userOptions() {
      userOptions().then((res) => {
        this.diffShareData = res.result;
      });
    },
    // 组织框架
    OrganizinList() {
      permissionTree({ type: "团队" }).then((res) => {
        this.teamValueOptions = res.result;
      });
    },
    // 新增分享
    addShares(row, inx) {
      this.fileList.shares.push({
        shareTypeDefault: "同事",
        type: "同事",
        shareIds: [],
        teamIds: [],
      });
    },
    // 同事
    workmate(e, inx) {
      this.isdisabled = false;
      this.fileList.shares[inx].shareIds = e;
    },
    // 团队
    teammate(e, inx) {
      this.isdisabled = false;
      this.fileList.shares[inx].teamIds = e;
    },
    diffType(val) {
      if (val == "同事") {
        this.userOptions();
        this.teamValue = [];
      } else if (val == "团队") {
        this.OrganizinList();
        this.diffTypeVal = "";
      } else {
        this.isdisabled = false;
      }
    },
    // 删除分享新增
    delShares(row, inx) {
      this.isShowWrapper = true;
      if (inx == 0) {
        this.isShowBtn = true;
      }
      this.fileList.shares.splice(inx, 1);
    },
    // 新增，编辑
    addOrEditFavorite(formName) {
      let req = {
        shareEmailTemplateVos: this.fileList.shares,
        templateIds: this.nameidList,
      };
      shareEmailTemplate(req).then((res) => {
        if (res.success) {
          this.$message.success("操作成功！");
          this.cleanEmailTemplate();
          this.createFile = false;
          this.getEmailTemplatePage();
        }
        this.saveloading = false;
      });
    },
    saveInfos() {
      this.$refs.addOrEdit.save("dataform").then((value) => {
        this.saveloading = false;
        this.drawerVisible = false;
        this.getEmailTemplatePage();
      });
    },
    // 关闭
    handleClose() {
      //   this.$refs.addNew.cleanFrom("from");
      this.drawerVisible = false;
    },
    goEmailGetCode() {
      //pdf文件查看
      window.open(
        "/pdf/web/viewer.html?file=" +
          PreviewUrl +
          "/pics/email-authcode-operate-guide.pdf",
        "_blank"
      );
    },
    cleanEmailTemplate() {
      this.nameList = [];
      this.nameidList = [];
      this.fileList = {
        id: "",
        name: "",
        shares: [
          {
            shareTypeDefault: "同事",
            shareIds: [],
            teamIds: [],
            type: "同事",
          },
        ],
        type: "",
        version: 0,
      };
    },
    handleClose1() {
      this.cleanEmailTemplate();
      this.createFile = false;
    },
  },
};
</script>
<style lang="scss">
.emailSet {
  .el-tabs__nav-scroll {
    padding: 0 15px;
  }
  .shareRow {
    margin-bottom: 10px;
  }
  .el-icon-plus:hover {
    color: #526ecc;
  }
  .el-icon-close:hover {
    color: #e60012;
  }
}
</style>